<template>
  <div class="page">
    <div class="tc">
      <div class="user-header">
        <div class="user-avatar">
          <van-icon class="user-icon" name="user-o"></van-icon>
        </div>
        <div
          @click="$router.push({ name: 'login' })"
          class="user-login-btn"
          v-if="!user"
        >
          登陆 / 注册
        </div>
        <div class="user-login-btn" v-else>
          <div
            @click="
              $router.push({
                name: 'userinfo',
                params: { fromPath: $route.fullPath },
              })
            "
            v-text="user.username"
          ></div>
          <p @click="$router.push({ name: 'buyfen' })">
            点数: <span v-text="user.userfen"></span>
            <van-icon class="warning ml-5" name="gem-o"></van-icon>
          </p>
        </div>
      </div>
    </div>
    <div>
      <van-cell-group title="账号">
        <van-cell
          :to="{ name: 'changePassword' }"
          icon="edit"
          is-link
          title="更改登陆密码"
        />
        <van-cell
          :to="{ name: 'collection' }"
          icon="star-o"
          is-link
          title="我的收藏"
        />
        <van-cell
          :to="{ name: 'hist' }"
          icon="browsing-history-o"
          is-link
          title="浏览足迹"
        />
      </van-cell-group>
      <van-cell-group title="客服">
        <van-cell
          :to="{ name: 'kefu' }"
          icon="chat-o"
          is-link
          title="联系客服"
        ></van-cell>
      </van-cell-group>
      <van-cell-group title="消费管理">
        <van-cell
          :to="{ name: 'buyfen' }"
          icon="balance-o"
          is-link
          title="在线充值"
        />
        <van-cell
          :to="{ name: 'buybak' }"
          icon="bill-o"
          is-link
          title="充值记录"
        />
        <van-cell
          :to="{ name: 'downbak' }"
          icon="balance-list-o"
          is-link
          title="下载消费记录"
        />
      </van-cell-group>
      <van-cell-group title="商城">
        <van-cell
          :to="{ name: 'orders' }"
          icon="orders-o"
          is-link
          title="我的订单"
        />
      </van-cell-group>
      <van-cell-group class="mt-15" v-if="isLogin">
        <van-button @click="logout" block>退出登录</van-button>
      </van-cell-group>
    </div>
  </div>
</template>

<script>
import { listMixin } from "@/libs/util";
import Vue from "vue";
import { Row, Col, Tag, Cell, CellGroup, loading, Button, Dialog } from "vant";

Vue.use(Row)
  .use(Col)
  .use(Tag)
  .use(Cell)
  .use(CellGroup)
  .use(loading)
  .use(Button)
  .use(Dialog);
export default {
  name: "user",
  mixins: [listMixin],
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  methods: {
    logout() {
      Dialog.confirm({ message: "退出当前账号？" })
        .then(() => {
          this.doLogout().then(() => {
            this.$store.dispatch("init");
            this.user = null;
          });
          this.$store.dispatch("logout", this);
          this.user = null;
        })
        .catch(() => {});
    },
  },
  created() {},
};
</script>

<style scoped>
.header-status {
  position: absolute;
  right: 10px;
  top: 5px;
}
.user-header {
  position: relative;
  background-color: rgb(55, 105, 199);
  color: #fff;
  width: 100%;
  height: 3.4rem;
}
.user-avatar {
  position: absolute;
  left: 0.5rem;
  top: 0.5rem;
  background-color: #fff;
  color: darkorange;
  border-radius: 999px;
  width: 2rem;
  height: 2rem;
  border: 0.2rem solid rgb(209, 209, 209);
}
.user-login-btn {
  position: absolute;
  left: 3.3rem;
  top: 1rem;
  font-size: 0.4rem;
}
.user-icon {
  font-size: 1.8rem;
}
</style>
